.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

.App-logo {
  background-color: #570000;
  height: 1em;
  padding: 0.5em;
  border-radius: 10%;
}

html {
  padding: 0;
  margin: 0;
  background-color: #eaeaea;
  color: #140000;
}

.body-content {
  min-height: calc(100vh - 100px - 250px);
  max-width: 800px;
  margin: 1rem auto;
}

button {
  background-color: #570000;
  color: #eaeaea;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: bold;
  margin: 1rem;
  transition-duration: 0.5s;
}

button:hover {
  color: black;
  background-color: #d58936;
}

.section {
  background-color: #bbbbbb;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section:last-child {
  margin-bottom: 0;
}

.section h2 {
  padding: 0;
  margin: 0;
  width: 560px;
}

.section p {
  width: 560px;
}

.section li {
  list-style-position: inside;
}
