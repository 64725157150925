.section .post-date {
  margin: 0;
  padding: 0;
}

@media (max-width: 580px) {
  .lesson-post {
    margin: 0 0 1rem 0;
    border-radius: 0;
  }
  .lesson-post h2 {
    width: 300px;
  }

  .lesson-post p {
    width: 300px;
  }
  .lesson-post iframe {
    width: 300px;
    height: 200px;
  }
}
