.fret {
  width: calc(6 * 3rem);
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  font-size: 0.75rem;
}

.note,
.f0-note {
  border: 0.5px solid black;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.note {
  background-color: #bbbbbb;
}

.v-line {
  position: absolute;
  height: 3rem;
  width: 5px;
  color: black;
  background-color: #570000;
}

.note-name {
  background-color: #eaeaea;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.fret-number {
  display: flex;
  align-items: center;
  justify-content: center;
}

.frets {
  margin-bottom: 1rem;
}

@media (min-width: 1500px) {
  .frets {
    display: flex;
    justify-content: center;
  }

  .fret {
    flex-direction: column;
    width: auto;
    margin: 0;
  }

  .v-line {
    width: 3rem;
    height: 5px;
  }
}
