.app-header {
  background-color: #140000;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.nav-links {
  background-color: #570000;
  width: 100%;

  list-style: none;
  display: flex;
  justify-content: center;
  line-height: 2rem;
  margin: 1rem 0 0 0;
  position: absolute;
  bottom: 0;
  font-weight: bold;
}

.nav-links li a {
  color: #eaeaea;
  text-decoration: none;
  transition-duration: 0.5s;
}

.nav-links li a:hover {
  color: #d58936;
}

.nav-links li {
  padding-right: 1rem;
}

.nav-links li:hover {
  color: #d58936;
}

.nav-links li:last-child {
  padding-right: none;
}
