.app-footer {
  background-color: #140000;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-links {
  color: #eaeaea;
}

.footer-links ul {
  list-style: none;
  display: flex;
}

.footer-links li {
  margin-right: 1rem;
  font-size: 1.25rem;
}

.footer-links li:last-child {
  margin-right: 0rem;
}

.footer-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #eaeaea;
  transition-duration: 0.5s;
}

.footer-links li a:hover {
  color: #d58936;
}
